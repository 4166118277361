jQuery(document).ready(function ($) {
    jQuery("#avaporitwnptorishpoiwta").change(function () {
      // Deselect all includes
      $(".MOD").removeAttr("checked");
  
      // alert('test');
      var department = jQuery(this).val();
      // alert(department);
  
      var nonce = jQuery("#nonceForJS").val();
      var postValues =
        "action=getDepartmentIncludes&department=" +
        department +
        "&nonce=" +
        nonce;
      var xhr = new XMLHttpRequest();
      // req.responseType = "json";
      xhr.open("POST", "../wp-admin/admin-ajax.php", true);
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      xhr.send(postValues);
  
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          console.log("Return Values: " + xhr.responseText);
          var departmentIncludes = jQuery.parseJSON(xhr.responseText);
          console.log(departmentIncludes);
          jQuery(".MOD").prop("checked", false);
          $.each(departmentIncludes, function (key, value) {
            jQuery("#MOD_" + value).prop("checked", true);
          });
        }
      };
    });
  
    jQuery("#udpateEmployeeSetNewPassword").click(function () {
      var newPassword = Math.random().toString(36).slice(-8);
      jQuery(".formSetNewPassword2").show();
      jQuery("#formSection_sendEmail").show();
      jQuery("#sendEmail").attr("checked", true);
      jQuery("#sfbsthshshsghnsghxhsfg").val(newPassword);
    });
  
    jQuery(".formSetNewPassword2Cancel").click(function () {
      jQuery(".formSetNewPassword2").hide();
      jQuery("#formSection_sendEmail").hide();
      jQuery("#sendEmail").attr("checked", false);
      jQuery("#sfbsthshshsghnsghxhsfg").val("");
    });
  
    jQuery(".formSetNewPassword2Hide").click(function () {
      var type = jQuery("#sfbsthshshsghnsghxhsfg").attr("type");
      // alert(type);
      if (type == "text") {
        jQuery("#sfbsthshshsghnsghxhsfg").attr("type", "password");
        jQuery(this).html(
          '<span id="formSetNewPassword2HideIcon"><i class="fas fa-eye"></i></span> Show'
        );
      } else {
        jQuery("#sfbsthshshsghnsghxhsfg").attr("type", "text");
        jQuery(this).html(
          '<span id="formSetNewPassword2HideIcon"><i class="fas fa-eye-slash"></i></span> Hide'
        );
      }
    });
  });
  