jQuery(document).ready(function ($) {
  jQuery(".unfinishedModulesNumberShowAll").on("click", function () {
    jQuery(".unfinishedModulesTableLI").show()
    jQuery(".pastDueModulesNumberOnly").show()
    jQuery(".unfinishedModulesNumberShowAll").hide()
  })

  jQuery(".pastDueModulesNumberOnly").on("click", function () {
    jQuery(".unfinishedModulesTableLI").hide()
    jQuery(".deadlineStatus_past").show()
    jQuery(".pastDueModulesNumberOnly").hide()
    jQuery(".unfinishedModulesNumberShowAll").show()
  })
})
