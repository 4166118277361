jQuery(document).ready(function ($) {
  jQuery(".moduleButton").on("click", function () {
    var moduletype = jQuery(this).attr("moduletype")
    // alert(moduletype)
    var memberId = jQuery(this).attr("memberId")
    // alert(memberId)
    if (moduletype == "unfinished") {
      jQuery("#memberRowUnfinishedModulesContent_" + memberId).toggle(300)
    }
    if (moduletype == "finished") {
      jQuery("#memberRowFinishedModulesContent_" + memberId).toggle(300)
    }
    if (moduletype == "pastdue") {
      jQuery("#memberRowPastDueModulesContent_" + memberId).toggle(300)
    }
  })
})
