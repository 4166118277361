jQuery(document).ready(function ($) {
  var showDeleteRow = false;

  jQuery(".ol_hr_admin_content_modules_header_el_delete").click(function () {
    var id = jQuery(this).attr("id");
    id = id.split("_");
    id = id[8];
    if (showDeleteRow == false) {
      jQuery("#ol_hr_admin_content_modules_list_record_delete_" + id).css(
        "display",
        "grid"
      );
      showDeleteRow = true;
    } else {
      jQuery(".ol_hr_admin_content_modules_list_record_delete").css(
        "display",
        "none"
      );
      showDeleteRow = false;
    }
  });

  jQuery(".ol_hr_admin_content_modules_list_record_delete_no").click(
    function () {
      var id = jQuery(this).attr("id");
      id = id.split("_");
      id = id[9];
      jQuery("#ol_hr_admin_content_modules_list_record_delete_" + id).css(
        "display",
        "none"
      );
      showDeleteRow = false;
    }
  );
});
