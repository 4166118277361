jQuery(document).ready(function ($) {

    jQuery('.onboardingStatusContentULLIUpdate').on('click', function(){
        console.log('onboardingStatusContentULLIUpdate was clicked');
        var id = jQuery(this).attr('id');
        var idSplit = id.split('_');
        console.log('ID: ' + [idSplit[1]]);
        jQuery('#onboardingStatusContentLIBottom_' + idSplit[1]).toggle();
    });

    jQuery('.onboardingStatusContentLIBottomLINo').on('click', function(){
        console.log('onboardingStatusContentLIBottomLINo was clicked');
        var id = jQuery(this).attr('id');
        var idSplit = id.split('_');
        console.log('ID: ' + [idSplit[1]]);
        jQuery('#onboardingStatusContentLIBottom_' + idSplit[1]).hide();
    });

    jQuery('#onboardingEmployeesAssignAssetNew').on('click', function(){
        console.log('onboardingEmployeesAssignAssetNew as clicked');
        jQuery('#onboardingEmployeesAssignAssetNewContent').toggle();
    });


    jQuery('.onboardingEmployeesContentListItemLI').on('click', function(){
        var id = jQuery(this).attr('id');
        var idSplit = id.split('_');
        var employeeId = idSplit[1];
        if ( jQuery('#offboardingConfirmCheck_' + employeeId).val() == 'notChecked' ) {
            jQuery('#offboardingConfirm_' + employeeId).css('display','grid');
            jQuery('#offboardingConfirmCheck_' + employeeId).val('checked');
        } else {
            jQuery('#offboardingConfirm_' + employeeId).css('display','none');
            jQuery('#offboardingConfirmCheck_' + employeeId).val('notChecked');
        }
    });

    jQuery('.onboardingEmployeesContentULLIBottomNo').on('click', function(){
        var id = jQuery(this).attr('id');
        var idSplit = id.split('_');
        var employeeId = idSplit[1];
        jQuery('#offboardingConfirm_' + employeeId).css('display','none');
        jQuery('#offboardingConfirmCheck_' + employeeId).val('notChecked');
    });

    jQuery('.onboardingAssetContentListItemLI').on('click', function(){
        var id = jQuery(this).attr('id');
        var idSplit = id.split('_');
        var assetId = idSplit[1];
        if ( jQuery('#deleteAssetConfirmCheck_' + assetId).val() == 'notChecked' ) {
            jQuery('#deleteConfirm_' + assetId).css('display','grid');
            jQuery('#deleteAssetConfirmCheck_' + assetId).val('checked');
        } else {
            jQuery('#deleteConfirm_' + assetId).css('display','none');
            jQuery('#deleteAssetConfirmCheck_' + assetId).val('notChecked');
        }
    });

    jQuery('.onboardingAssetsContentULLIBottomNo').on('click', function(){
        var id = jQuery(this).attr('id');
        var idSplit = id.split('_');
        var employeeId = idSplit[1];
        jQuery('#deleteConfirm_' + employeeId).css('display','none');
        jQuery('#deleteAssetConfirmCheck_' + employeeId).val('notChecked');
    });
    


    jQuery('.onboardingAssetsULLI_unassing').on('click', function(){
        var id = jQuery(this).attr('id');
        var idSplit = id.split('_');
        var assignedAssetId = idSplit[1];
        if ( jQuery('#deleteAssignedAssetConfirmCheck_' + assignedAssetId).val() == 'notChecked' ) {
            jQuery('#onboardingAssetsLIBottom_' + assignedAssetId).css('display','grid');
            jQuery('#deleteAssignedAssetConfirmCheck_' + assignedAssetId).val('checked');
        } else {
            jQuery('#onboardingAssetsLIBottom_' + assignedAssetId).css('display','none');
            jQuery('#deleteAssignedAssetConfirmCheck_' + assignedAssetId).val('notChecked');
        }
    });

    jQuery('.onboardingAssetsLIBottomNo').on('click', function(){
        var id = jQuery(this).attr('id');
        var idSplit = id.split('_');
        var assignedAssetId = idSplit[1];
        jQuery('#onboardingAssetsLIBottom_' + assignedAssetId).css('display','none');
        jQuery('#deleteAssignedAssetConfirmCheck_' + assignedAssetId).val('notChecked');
    });
});
  